<template>
  <div>
    <p class="msg">
      <van-icon
        name="arrow-left"
        size="0.34rem"
        style="margin-left: 0.2rem"
        @click="$router.push('/resume')"
      />
      <span class="wan">完善信息</span>
    </p>
    <div class="identity">
      <p class="buddha">
        <span class="sr ss" @click="show1 = true">学历</span>
        <input
          class="lian"
          type="text"
          readonly="readonly"
          v-model="education"
          placeholder="请选择学历"
          style="text-align: right; width: 6rem"
          @click="show1 = true"
        />
      </p>
      <div class="schoolclass">
        <div @click="$router.push('/school')" id="buddha2" class="buddha">
          <span class="sr ss schollcla">教育院校及专业</span>
          <van-icon color="#FF5F19" size=".4rem" name="add-o" />
        </div>
        <div v-for="(item,index) in schoolobj" v-bind:key="index" class="s1">
          <div class="s2">
            <div>{{item.school}}</div>
            <div>{{item.start_time}} 至 {{item.end_time}}</div>
          </div>
          <div class="s3">{{item.education_background}}-{{item.major}}</div>
        </div>
      </div>

      <van-popup v-model="show1" position="bottom">
        <van-picker
          title="学历"
          show-toolbar
          :columns="columns2"
          @confirm="confirm1"
          @cancel="show1 = false"
        />
      </van-popup>
      <van-popup v-model="showeducation2" position="bottom">
        <van-picker
          title="学历"
          show-toolbar
          :columns="columns2"
          @confirm="onConfirm5"
          @cancel="showeducation = false"
        />
      </van-popup>
      <p class="buddha">
        <span class="sr ss" @click="showpolitics = true">政治面貌</span>
        <input
        readonly="readonly"
          class="lian"
          type="text"
          v-model="politics"
          style="text-align: right; width: 5rem"
          placeholder="请选择政治面貌"
          @click="showpolitics = true"
        />
      </p>
      <!-- 选择政治面貌 -->
      <van-popup v-model="showpolitics" position="bottom">
        <van-picker
          title="政治面貌 "
          show-toolbar
          :columns="columns1"
          @confirm="onConfirm2"
          @cancel="showpolitics = false"
        />
      </van-popup>
      <p class="buddha">
        <span class="sr ss" @click="show = true">工作年限(年)</span>
        <input
        readonly="readonly"
          class="lian"
          type="text"
          v-model="time"
          placeholder="请选择工作年限"
          style="text-align: right; width: 4.5rem"
          @click="show = true"
        />
      </p>
      <van-popup v-model="show" position="bottom">
        <van-picker
          title=""
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
        />
      </van-popup>
      <!-- 日期 -->
      <van-popup v-model="shows" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
          @cancel="shows = false"
          @confirm="qd"
        />
      </van-popup>
    </div>
    <div>
      <p class="btn" @click="check" id="triggerBtn">下一步</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      schoolarray: [
        {
          school: "", //学校名称
          education_background: "", //学历
          major: "", //专业
          start_time: "", //入学时间
          end_time: "", //毕业时间
        },
      ],
      show1: false,
      shows: false, //时间
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      show: false,
      politics: "",
      major: "", //学院和专业
      showeducation: false,
      showeducation2: false,
      time: "",
      showpolitics: false,
      education: "",
      columns2: [
        "初中及以下",
        "中专/中技",
        "大专",
        "本科",
        "硕士",
        "博士",
      ],
      // 学历选择

      columns1: ["群众", "党员", "民主党派"],
      columns: [
        "应届",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "20年以上",
      ],
      xueliindex: "",
      schoolobj:[]
    };
  },
  created() {
    let allmsg = JSON.parse(localStorage.getItem("allmsg"));
    this.schoolobj = window.schoolobj;
    if(!this.schoolobj&&allmsg.educational_experience){
      this.schoolobj = JSON.parse(allmsg.educational_experience);
    }
    localStorage.setItem("schoolmsg",JSON.stringify(this.schoolobj));
    
    this.politics = allmsg.politics;
    this.time = localStorage.getItem("agelimit");
     if(allmsg.years_working){
      this.time = allmsg.years_working;
    }
    this.education = localStorage.getItem("education");
    this.major = localStorage.getItem("major");
    if (this.education == null) {
      this.education = "本科";
    }
    if (this.politics == null) {
      this.politics = "群众";
    }
  },
  methods: {
    confirm1(value) {
      this.show1 = false;
      this.education = value;
      localStorage.setItem("education", value);
    },
    addschmeth() {
      this.$set(this.schoolarray, this.schoolarray.length, {
        school: "", //学校名称
        education_background: "", //学历
        major: "", //专业
        start_time: "", //入学时间
        end_time: "", //毕业时间
      });
    },
    closeschmeth() {
      this.$delete(this.schoolarray, this.schoolarray.length - 1);
    },
    //确定
    onConfirm(val) {
      this.time = val;
      this.show = false;
    },
    //取消
    onCancel() {
      this.show = false;
    },
    //确定
    onConfirm2(value, index) {
      this.showpolitics = false;
      this.politics = value;
      localStorage.setItem("face", value);
    },
    inputmeth(index) {
      this.showeducation = true;
      this.xueliindex = index;
    },
    inputmeth(index) {
      this.shows = true;
      this.xueliindex = index;
    },
    onConfirm1(value, index) {
      // showeducation
      this.showeducation = false;
      console.error(this.xueliindex);
      // this.education = value;
      let a = this.schoolarray[this.xueliindex];
      a.education_background = value;
      this.$set(this.schoolarray, this.xueliindex, a);
      localStorage.setItem("education", value);
    },
    onConfirm5(value) {
      this.showeducation2 = false;
      this.education = value;
    },
    qd(value) {
      this.shows = false;
      // let a = this.schoolarray[this.xueliindex];
      // a.education_background = value;
      // this.$set(this.schoolarray,this.xueliindex,a);
      // localStorage.setItem("education", value);
    },
    //保存内容进入下一步
    check() {
      if (this.politics == null) {
        this.$toast.fail("请填写政治面貌");
        return;
      }
      if (this.time == null) {
        this.$toast.fail("请选择工作年限");
        return;
      }
      if (this.education == null) {
        this.$toast.fail("请选择学历");
        return;
      }
      if(!this.schoolobj){
         this.$toast.fail("请填写教育院校及专业");
         return;
      }
      // if (this.major == null) {
      //   this.$toast.fail("请填写教育院校及专业");
      //   return;
      // }
      if (this.time == "20年以上") {
        localStorage.setItem("agelimit", 20);
      } else {
        localStorage.setItem("agelimit", this.time);
      }
      localStorage.setItem("major", this.major);
      localStorage.setItem("face", this.politics);
      localStorage.setItem("education", this.education);
      let id = localStorage.getItem("id");
      this.$http
        .post("/firm/v1/Resume/improveInfo", {
          reqType: "resume",
          id: id,
          education_background: this.education,
          school_major: this.major,
          politics: this.politics,
          years_working: this.time=='应届'?'0':this.time,
          educational_experience: this.schoolobj,
          pageno: 3, //页码
        })
        .then((res) => {
          res = JSON.parse(res.data);
          console.log(res);
          if (res.code == 0) {
            this.$router.push("/resume2");
          } else {
            this.$toast.fail(res.msg);
          }
        })
        .catch((eer) => {
          console.log(eer);
        });
    },
  },
};
</script>

<style scoped lang='less'>
.schoolclass{
  border-bottom: solid 0.01rem #eee;
  padding: .63rem 0;
}
.identity .schoolclass .buddha{
  border-bottom: none;
}
.s1{
  margin: 0 .2rem;
}
.s2{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .13rem;
  margin-top: .15rem;
}
.s2 > :first-child {
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #333333;
}
.s2 > :nth-child(2) {
  color: #333333;
  font-size: 0.28rem;
  line-height: 0.26rem;
}
.s3{
  font-size: .3rem;
  line-height: .33rem;
  color: #333333;
}
.addclose {
  display: flex;
  align-items: center;
}
.addclose > :first-child {
  margin-right: 0.3rem;
}
.addclose > :nth-child(2) {
  background-color: #f55613;
  border: 1px solid #f55613;
}
.scclas {
  margin-bottom: 0.3rem;
}
.addschoolclass {
  display: flex;
  align-items: center;
  border: 1px solid #38f;
  width: 1.5rem;
  justify-content: center;
  background-color: #38f;
  color: #ffffff;
  border-radius: 0.1rem;
  margin-top: 0.3rem;
  padding: 0.05rem;
}
.smsg {
  display: flex;
  align-items: center;
}
.smsg input {
  border: none;
  width: 2.5rem;
}
.smsg > :nth-child(2) {
  border-bottom-color: #697386;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  font-size: 0.3rem;
  padding: 0.1rem 0;
  width: 2.5rem;
}
.schollcla {
  // min-width: 2.6rem;
}
.schoolclass {
  font-size: 0.3rem;
  line-height: 0.4rem;
}
.msg {
  height: 0.88rem;
  line-height: 0.88rem;
  line-height: 0.48rem;
  border-bottom: solid 0.01rem #eeeeee;
}
.ss::before {
  content: "*";
  color: #ff0000;
  font-size: 0.25rem;
  position: relative;
  margin-right: 0.1rem;
  /* top: 0.1rem; */
}
.msg .wan {
  margin-left: 2.5rem;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}
.identity {
  margin: 0rem 0.32rem;
}

.identity .buddha {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 0.01rem #eee;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #697386;
  line-height: 1.46rem;
}

.identity .buddha .lian {
  border: none;
}

.btn {
  width: 6.86rem;
  height: 0.96rem;
  background: linear-gradient(90deg, #f55613 0%, #fc9e46 100%);
  border-radius: 0.48rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 0.96rem;
  margin-top: 1.38rem;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.78rem;
}
#buddha2 > .schollcla {
  line-height: 0.4rem;
}
</style>